import { isEqual } from 'lodash'
import { RuleType } from '../redux/cases/caseSlice'
import { Gender, OSTEOCHECK_MOD_STUDY, TB_SCREENING_MOD_STUDY, XRAY_STUDY } from './constants'
import { InFlightNames, InFlightStatuses, InFlightsType } from './types'

export const isInFlightPending = (inFlights: InFlightsType, inFlightName: InFlightNames) => {
  return inFlights[inFlightName]?.status === InFlightStatuses.PENDING
}

export const isInFlightSuccess = (inFlights: InFlightsType, inFlightName: InFlightNames) => {
  return inFlights[inFlightName]?.status === InFlightStatuses.SUCCESS
}

export const isInFlightFailed = (inFlights: InFlightsType, inFlightName: InFlightNames) => {
  return inFlights[inFlightName]?.status === InFlightStatuses.ERROR
}

export const getDefaultGender = (patientGender: string) => {
  const gender = patientGender?.toLowerCase()
  return ['female', 'f']?.includes(gender) ? Gender?.FEMALE : ['male', 'm']?.includes(gender) ? Gender.MALE : ''
}

export const filterModstudies = (modStudies: RuleType[], additionalToExclude?: number[]) => {
  const studiesToExclude: number[] = [
    OSTEOCHECK_MOD_STUDY,
    TB_SCREENING_MOD_STUDY,
    XRAY_STUDY,
    ...(additionalToExclude ?? []),
  ]
  return modStudies?.filter(rule => {
    return !studiesToExclude.includes(rule?.id)
  })
}

export const editorSectionToUpdate = (prevEditorValue: Array<any>, newEditorValue: Array<any>) => {
  const sectionsToUpdate: { [index: number]: Node | null } = {}
  newEditorValue.forEach((newEditorValueSection, index) => {
    const prevEditorValNode = prevEditorValue[index]
    if (prevEditorValNode) {
      // Node exists in preview editor value but has changed
      if (!isEqual(prevEditorValNode, newEditorValueSection)) {
        sectionsToUpdate[index] = newEditorValueSection
      }
    } else {
      // Node doesn't exists in preview editor value
      sectionsToUpdate[index] = newEditorValueSection
    }
  })

  // Check for deleted sections
  prevEditorValue.forEach((prevEditorValueSection: any, index: number) => {
    if (!newEditorValue[index]) {
      sectionsToUpdate[index] = null
    }
  })

  return sectionsToUpdate
}

export const getRuleName = (ruleObj: RuleType | undefined, separator = ' ') => {
  if (ruleObj) {
    let ruleName = ruleObj['list']?.join(separator)

    if (ruleObj.hasOwnProperty('children')) {
      ruleName += separator
      ruleObj['children'].forEach((childObj: any, index: number) => {
        ruleName += childObj['list'].join(' ')
        if (index !== ruleObj['children'].length - 1) {
          ruleName += ' and '
        }
      })
    }

    if (ruleObj.hasOwnProperty('additional') && ruleObj['additional'].length) {
      ruleName += ' with '
      ruleObj['additional'].forEach((additionalRule: any, index: number) => {
        ruleName += additionalRule['list']
          .filter((listItem: any, liIndex: number) => {
            // Omit the first list item if it is same as first item of rule list
            return !(!Boolean(liIndex) && listItem === ruleObj['list'][0])
          })
          .join(' ')

        if (index !== ruleObj['additional'].length - 1) {
          ruleName += ' and '
        }
      })
    }

    if (ruleObj?.['mandatory']?.length) {
      // Sort mandatory rules array by type
      const newArray = [...ruleObj['mandatory']]
      newArray.sort((a: any, b: any) => {
        const ruleTypes = ['Gender', 'Side', 'View']
        return ruleTypes.indexOf(a.type) - ruleTypes.indexOf(b.type)
      })
      ruleName += ' - '
      let mandatoryRules = ''
      newArray.forEach((mandatoryRule: any, index: number) => {
        let valuesString = mandatoryRule['values'].join(', ')
        if (mandatoryRule['values'].length > 1) {
          const andIndex = valuesString.lastIndexOf('and ')
          if (andIndex < 0) {
            const lastCommaIndex = valuesString.lastIndexOf(',')
            valuesString = valuesString.slice(0, lastCommaIndex) + ' and ' + valuesString.slice(lastCommaIndex + 1)
          } else {
            valuesString = valuesString.replace(/, ([^,]+)$/, ' and $1')
          }
        }
        mandatoryRules += valuesString
        if (index < newArray.length - 1) {
          if (mandatoryRule['type'] !== newArray[index + 1]['type']) {
            mandatoryRules += ' - '
          } else {
            mandatoryRules += ' and '
          }
        }
      })
      ruleName += mandatoryRules
    }

    return ruleName
  }

  return ''
}

export const doesRuleNamesMatch = (firstRule: string, secondRule: string) => {
  firstRule = firstRule.toLowerCase()
  secondRule = secondRule.toLowerCase()
  let firstRuleArray = firstRule?.split('-')?.join('')?.split(' ')
  let secondRuleArray = secondRule?.split('-')?.join('')?.split(' ')

  firstRuleArray = firstRuleArray?.filter(word => {
    return Boolean(word?.length)
  })
  secondRuleArray = secondRuleArray?.filter(word => {
    return Boolean(word?.length)
  })

  if (firstRuleArray?.length >= secondRuleArray?.length) {
    const isMatching = firstRuleArray?.every((word: string) => {
      return secondRuleArray?.includes(word)
    })
    return isMatching
  }

  const isMatching = secondRuleArray?.every((word: string) => {
    return firstRuleArray?.includes(word)
  })
  return isMatching
}
