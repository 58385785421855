export enum Actions {
  //auth
  requestLogin = 'requestLogin',
  requestUpdateUserStatus = 'requestUpdateUserStatus',
  requestUpdateAuthStatus = 'requestUpdateAuthStatus',
  requestLogout = 'requestLogout',
  requestUserInfo = 'requestUserInfo',

  // cases
  requestNewCase = 'requestNewCase',
  requestCasesInPoolCount = 'requestCasesInPoolCount',
  requestCaseBasedOnDate = 'requestCaseBasedOnDate',
  requestSkipReasons = 'requestSkipReasons',
  requestModStudies = 'requestModStudies',
  requestUpdateModStudy = 'requestUpdateModStudy',
  requestHilEligibleStudies = 'requestHilEligibleStudies',
  requestEligibleOnlineUsers = 'requestEligibleOnlineUsers',

  // reports
  requestGetStudy = 'requestGetStudy',
  requestGetReportTemplate = 'requestGetReportTemplate',
  requestGetDicomUrlToken = 'requestGetDicomUrlToken',
  requestTakeAction = 'requestTakeAction',
  requestSendBackToHilPool = 'requestSendBackToHilPool',

  // settings
  requestUpdateSettings = 'requestUpdateSettings',
  requestGetSettings = 'requestGetSettings',

  //xqc
  requestQcInfo = 'requestQcInfo',
  requestAcceptCase = 'requestAcceptCase',
}
