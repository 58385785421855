import { CaseType, ReasonItemType, ReasonTypes, RuleType } from './caseSlice'

export enum ruleUpdateResponseAssignmentResponse {
  SEND_TO_HIL_POOL = 'send_to_hil_pool',
  SEND_TO_RAD_POOL = 'send_to_rad_pool',
  SEND_TO_MODEL = 'send_to_model',
  NOT_CHANGED = 'not_changed',
}

export type QcInfoObjectType = {
  study_id: number
  patient_name: string
  patient_id: string
  patient_age: string
  patient_sex: string
  order_id: string
  image_count: number
  series_count: number
  mod_study_names?: Array<string>
  rules: Array<RuleType>
  client: any
  history: string
  reports: Array<{
    report_qc: { id: number }
    json: any
    id: any
  }>
  qc_edited_reports: Array<{
    json: any
    id: any
  }>
  post_operative: string
  sub_optimal: boolean
  critical: boolean
  study_status: string
  client_requirements?: Array<string>
  linked_studies_details?: any
  is_churn_risk?: boolean

  technician_details?: {
    name: string
    contact_no: string
  }
  iqc_comment?: {
    remarks: string
  }
  merged_study_iuids: string[]
  is_pro_rad_insights: boolean

  id?: number //studyQc id
  so_id?: number
  so_status?: string
  priority?: string
  qc_status?: string
  pr_id?: number
  createdAt: string
  is_urgent?: boolean
  oms_partner?: boolean
  is_demo?: boolean
  other_details: any
}

export type onReceiveGetCasesInPoolCountPayloadType = {
  pool_count: number
}

export type RequestGetCasesInPoolCountPayloadType = {
  email: string | undefined
}

export type RequestGetNewCasePayloadType = {
  email: string | undefined
}

export type onReceiveGetNewCasePayloadType = {
  details: CaseType
}

export type RequestCaseByDatePayloadType = {
  formattedDate: any
  email: string | undefined
}

export type onReceiveSkipReasonsPayloadType = ReasonItemType[]

export type RequestSkipReasonPayloadType = {
  reasonType: ReasonTypes
}

export type OnReceiveModStudyType = RuleType[]

export type OnRequestUpdateModStudy = {
  study_id: number
  rules: RuleType[]
  by_user_id?: number | string
}

export type OnRequestEligibleOnlineUsers = {
  mod_study: number | string
  type_id: number
}

export type OnReceiveQCInfoPayloadType = { qcInfoObject: QcInfoObjectType }
export type RequestQCInfoPayloadType = { studyId: number }

export type RequestAcceptCasePayloadType = {
  qcId: number
  email: string
  studyId: number
  closeWindow: boolean
  reportIds?: number[]
  sections?: { [key: number]: Node | null }
}
