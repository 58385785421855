import { useState, Suspense, useEffect, useMemo } from 'react'
import { Flex, Layout, Spin, Typography } from 'antd'
import '../style/globals.css'
import { FullScreenSpinner } from '../components/shared/CustomSpinners'
import styled from 'styled-components'
import Viewer from '../components/Viewer'
import { theme } from '../components/shared/theme'
import { Content } from 'antd/es/layout/layout'
import OverlayLoader from '../components/shared/OverlayLoader'
import { InFlightNames } from '../utils/types'
import { editorSectionToUpdate, isInFlightPending } from '../utils/helper'
import OverlayModal from '../components/shared/OverlayModal'
import { requestAcceptCase, requestQcInfo, StudyStatuses } from '../redux/cases/caseSlice'
import { useAppDispatch, useAppSelector } from '../redux/utils/hooks'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { TextColors } from '../utils/constants'
import { Button as AntButton } from 'antd'
import PatientDetails from '../components/cases/PatientDetails'
import { PlateEditor } from '../components/plate-editor'
import { auth } from '../Auth/firebaseAuth'
let resize = false

type reportData = {
  studyData: any
  viewer: any
}

const QcReporting = (data: reportData) => {
  const dispatch = useAppDispatch()
  const { qcStudyInfo, inFlights } = useAppSelector(state => state.cases)
  const { studyData, viewer } = data

  const getInitialEditorValue = () => {
    if (Boolean(qcStudyInfo.reports?.[0]?.json?.length) || Boolean(qcStudyInfo?.qc_edited_reports?.[0]?.json?.length)) {
      return Boolean(qcStudyInfo?.qc_edited_reports?.[0]?.json?.length)
        ? qcStudyInfo?.qc_edited_reports?.[0]?.json
        : qcStudyInfo.reports[0]?.json
    }
    return null
  }

  const getRecentReportIds = () => {
    if (qcStudyInfo?.qc_edited_reports?.length) {
      return qcStudyInfo?.qc_edited_reports?.map(report => report?.id).filter(val => val)
    }
    return qcStudyInfo?.reports?.map(report => report?.id).filter(val => val)
  }

  const [hideImageViewer, setHideImageViewer] = useState<boolean>(false)
  const [leftPaneWidth, setLeftPaneWidth] = useState<number>(0)
  const [userEmail, setUserEmail] = useState('')
  const [editorValue, setEditorValue] = useState<Array<Node>>(getInitialEditorValue())
  console.log({ qcStudyInfo })
  console.log({ editorValue })

  const isBlockUserModalOpen = useMemo(() => {
    return [StudyStatuses.NON_REPORTABLE, StudyStatuses.REPORTABLE, StudyStatuses.IN_POOL].includes(studyData?.status)
  }, [studyData])

  useEffect(() => {
    setLeftPaneWidth(screen.width / 2 - 1)
    dispatch(requestQcInfo({ studyId: studyData.study_id }))
  }, [])

  useEffect(() => {
    setEditorValue(getInitialEditorValue())
  }, [qcStudyInfo])

  useEffect(() => {
    window.addEventListener('mousemove', e => {
      if (resize && e.clientX > 400 && e.clientX < screen.width - 430) {
        setLeftPaneWidth(e.clientX)
      }
    })

    window.addEventListener('mouseup', () => {
      resize = false
      setHideImageViewer(false)
    })

    return () => {
      window.removeEventListener('mousemove', () => {})

      window.removeEventListener('mouseup', () => {})
    }
  }, [])

  useEffect(() => {
    const user = auth.currentUser
    if (user) {
      setUserEmail(user.email!)
    }
  }, [])

  const handleToggleResize = () => {
    resize = true
    setHideImageViewer(true)
  }

  const handleOnchangeEditorValue = (value: any) => {
    setEditorValue(value)
  }

  const handleEditAndAcceptReport = () => {
    dispatch(
      requestAcceptCase({
        email: userEmail,
        qcId: qcStudyInfo?.id as number,
        closeWindow: true,
        reportIds: getRecentReportIds(),
        sections: editorSectionToUpdate(qcStudyInfo?.reports?.[0].json, editorValue),
        studyId: qcStudyInfo.study_id,
      })
    )
  }

  const isActionPending = () => {
    return isInFlightPending(inFlights, InFlightNames.requestAcceptCaseInFlight)
  }

  const handleAcceptReport = () => {
    dispatch(
      requestAcceptCase({
        email: userEmail,
        qcId: qcStudyInfo?.id as number,
        closeWindow: true,
        reportIds: getRecentReportIds(),
        studyId: qcStudyInfo.study_id,
      })
    )
  }

  const getBlockUserModalText = () => {
    if (studyData?.status === StudyStatuses.NON_REPORTABLE) {
      return 'This case no longer exists for review. This case is skipped'
    } else if (studyData?.status === StudyStatuses.IN_POOL) {
      return 'This case is not assigned to user'
    }
    return 'This case has already been reported'
  }

  const renderEditor = () => {
    return (
      <EditorWrapperContainer>
        <StyledEditor>
          {!Boolean(editorValue?.length) ? (
            <Flex justify='center'>
              <Spin />
            </Flex>
          ) : (
            <PlateEditor
              editorValue={editorValue as any}
              handleOnchangeEditorValue={handleOnchangeEditorValue}
            />
          )}
        </StyledEditor>
      </EditorWrapperContainer>
    )
  }

  const renderEditorFooter = () => {
    return (
      <EditorFooterContainer>
        {
          <StyledAcceptButton
            onClick={handleAcceptReport}
            disabled={isActionPending()}
          >
            {isActionPending() ? <StyledSpin style={{ color: theme.colors.secondary }} /> : 'Accept'}
          </StyledAcceptButton>
        }
        <StyledButton
          onClick={handleEditAndAcceptReport}
          disabled={isActionPending()}
        >
          {isActionPending() ? <StyledSpin /> : 'Edit & Accept'}
        </StyledButton>
      </EditorFooterContainer>
    )
  }

  const renderSplitView = () => {
    return (
      <SplitViewContainer leftPane={leftPaneWidth}>
        <ViewerContainer>
          <SplitBlurDiv isBlur={hideImageViewer}>
            <Viewer
              hide={hideImageViewer}
              viewer={viewer}
            />
          </SplitBlurDiv>
        </ViewerContainer>
        <ResizeDragContainer onMouseDown={handleToggleResize}>
          <ResizeDragHandler resize={resize} />
        </ResizeDragContainer>
        <StyledEditorContainer>
          <PatientDetails patientDetails={studyData} />
          {renderEditor()}
          {renderEditorFooter()}
        </StyledEditorContainer>
      </SplitViewContainer>
    )
  }

  return (
    <StyledLayout>
      <OverlayLoader
        isLoading={isInFlightPending(inFlights, InFlightNames.requestQcInfoInFlight)}
        text={'Loading...'}
      />
      <OverlayModal
        isModalOpen={isBlockUserModalOpen}
        content={
          <Typography.Title
            level={5}
            style={{ margin: 0, padding: '1rem', textAlign: 'center' }}
          >
            <Flex
              gap='small'
              justify='center'
            >
              <ExclamationCircleOutlined style={{ color: theme.colors.warning }} />
              {getBlockUserModalText()}
            </Flex>
          </Typography.Title>
        }
      />
      <ContentContainer>
        <Suspense fallback={<FullScreenSpinner />}>{renderSplitView()}</Suspense>
      </ContentContainer>
    </StyledLayout>
  )
}

export default QcReporting

type SplitViewContainerProps = {
  leftPane: number
}

type SplitBlurDivType = {
  isBlur: boolean
}

type ResizeDragType = {
  resize: boolean
}

type ScreenType = {
  isSplitScreen?: boolean
}
export const StyledLayout = styled(Layout)`
  height: 99vh;
  background-color: ${props => props.theme.colors.appBackground};
`
export const ContentContainer = styled(Content)`
  position: relative;
`

const StyledButton = styled(AntButton)`
  background-color: ${theme.colors.black} !important;
  color: ${TextColors.appBackground} !important;
  padding: 1.3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  min-width: 8.5rem;
`
const SplitViewContainer = styled.div<SplitViewContainerProps>`
  display: grid;
  grid-template-columns: ${props => `${props.leftPane}px 1px auto`};
  grid-template-rows: auto;
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
`
const ViewerContainer = styled.div`
  overflow: auto;
  position: relative;
  height: 100%;
`

const SplitBlurDiv = styled.div<SplitBlurDivType>`
  background: ${props => props.theme.colors.black};
  filter: ${props => (props.isBlur ? 'blur(100px)' : 'none')};
  height: 100%;
`

const ResizeDragContainer = styled.div`
  height: inherit;
  z-index: 2;
  position: relative;
`
const ResizeDragHandler = styled.div<ResizeDragType>`
  background: ${props => props.theme.colors.ternaryDark};
  border: 1.5px solid ${props => props.theme.colors.ternary};
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  cursor: ${props => (props.resize ? 'grabbing' : 'grab')};
  height: 7rem;
  left: 0;
  position: absolute;
  top: calc(50% - 3.5rem);
  width: 1rem;
`

const StyledEditorContainer = styled.div<ScreenType>`
  background-color: 'white';
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const EditorWrapperContainer = styled.div`
  height: 100%;
  overflow: auto;
`

const StyledEditor = styled.div`
  height: calc(100vh - 4rem);
  overflow: hidden;
`

const EditorFooterContainer = styled(Flex)`
  box-shadow: 0 0 20px ${props => props.theme.boxShadows.buttonBoxShadow}64;
  gap: 1rem;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0.8rem 1rem;
  height: 4rem;
  bottom: 0;
  right: 0;
`

const StyledAcceptButton = styled(StyledButton)`
  background-color: ${theme.colors.success} !important;
  color: ${TextColors.appBackground} !important;
  border-color: ${theme.colors.success} !important;
`
const StyledSpin = styled(Spin)`
  color: ${theme.colors.secondary};
`
